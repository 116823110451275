/* Common component styles */
/* Style for buttons without severity */
.p-button.p-component:not(.p-button-success):not(.p-button-danger):not(.p-button-info):not(.p-button-warning):not(.p-button-help) {
    background: var(--complementary-color-25);
    background-color: var(--background-color);
    border: 1px solid var(--complementary-color-25);
    color: var(--complementary-color); /* Set label color */
}

.requests-pause-toggle-on .p-button {
    background-color: var(--background-color-lighter) !important;
    color: var(--complementary-color) !important;
}
.requests-pause-toggle-off .p-button {
    background-color: var(--background-color-darker) !important;
    color: var(--complementary-color) !important;
}

.p-button.p-component:not(.p-button-success):not(.p-button-danger):not(.p-button-info):not(.p-button-warning):not(.p-button-help):hover {
    background: var(--complementary-color);
    border: 1px solid var(--complementary-color-50);
    color: var(--background-color); /* Set label color */
}

.p-button.p-component:not(.p-button-success):not(.p-button-danger):not(.p-button-info):not(.p-button-warning):not(.p-button-help):hover .p-button-icon-left {
    color: var(--background-color);
}

.p-button.p-component:not(.p-button-success):not(.p-button-danger):not(.p-button-info):not(.p-button-warning):not(.p-button-help):enabled:hover {
    background: var(--complementary-color-darker);
    border-color: var(--complementary-color);
    color: var(--background-color);
}

/* Slider */
.p-slider .p-slider-handle:focus {
    box-shadow: none;
}
.p-slider .p-slider-handle {
    border: 2px solid var(--complementary-color);
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
    border-color: var(--complementary-color-lighter);
}
.p-slider .p-slider-range {
    background: var(--complementary-color-50);
}

/* Input switch */
.p-inputswitch.p-highlight .p-inputswitch-slider {
    background: var(--complementary-color);
}
.p-inputswitch.p-highlight .p-inputswitch-slider:before {
    background: var(--background-color-darker);
}
.p-inputswitch .p-inputswitch-slider {
    background: var(--background-color-darker);
}
.p-inputswitch .p-inputswitch-slider:before {
    background: var(--complementary-color);
}

/* Tab Menu */
.custom-tabmenu .p-menuitem-link {
    background-color: var(--background-75);
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    color: var(--complementary-color);
    border-color: var(--complementary-color) !important;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    color: var(--complementary-color-25);
    border-color: var(--complementary-color-25);
}

/* Dropdown */
.p-dropdown:not(.p-disabled):hover {
    border-color: var(--complementary-color);
}
span.p-dropdown-label.p-inputtext {
    color: var(--complementary-color);
}
.p-dropdown .p-dropdown-trigger {
    color: var(--complementary-color);
}
.p-dropdown {
    border: 1px solid var(--background-color);
}

/* Checkbox */
.p-checkbox .p-checkbox-box.p-highlight {
    background: var(--background-color);
    border-color: var(--complementary-color);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: var(--background-color-darker);
    border-color: var(--complementary-color-lighter);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: var(--complementary-color-lighter);
}
.p-checkbox {
    border-radius: 6px;
}

/* Highlighted ToggleButton */
.p-togglebutton.p-button.p-highlight {
    color: var(--complementary-color);
    border: 1px solid var(--background-color-lighter);
}
/* Icon color for highlighted ToggleButton */
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
    color: var(--complementary-color-darker);
}
.p-togglebutton.p-highlight .p-button .p-button-icon-left, .p-togglebutton.p-highlight .p-button .p-button-icon-right {
    color: var(--complementary-color);
}

/* Hover state for entire highlighted ToggleButton */
.p-togglebutton.p-button.p-highlight:hover {
    color: var(--complementary-color-lighter);
    border: 1px solid var(--background-color-lighter);
}

/* Change icon color on hover for highlighted ToggleButton */
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
    color: var(--complementary-color-lighter);
}

/* Input group addon styles */
.p-inputgroup-addon {
    color: var(--complementary-color);
    background-color: var(--background-color-50);
    border: 1px solid var(--background-color-lighter);
    border-right: none;
}

/* Input text styles */
.p-inputtext {
    border: 1px solid var(--background-color);
    color: var(--complementary-color);
}
.p-inputtext.p-component {
    background: var(--background-color-50);
}
.p-inputtext:enabled:focus {
    border-color: var(--complementary-color);
}
.p-inputtext:enabled:hover {
    border-color: var(--complementary-color);
}

/* Input number */
.p-inputnumber-button {
    color: black;
}

/* AI preset card background colors */
.ai-preset-card::before {
    background-color: var(--background-75);
}

.ai-preset-card:hover::before {
    background-color: var(--background-25);
}

.ai-preset-card:hover {
    background-color: var(--background-50);
}

/* Text lighter class */
.text-lighter {
    color: var(--complementary-color-lighter);
}

/* Top bar icon and text styles */
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    color: var(--complementary-color);
}
.p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: var(--complementary-color);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: var(--complementary-color);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: var(--complementary-color);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: var(--complementary-color-lighter);
}

/* Top bar selected state */
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: var(--complementary-color-lighter);
}

/* Toolbar */
.p-toolbar {
    background: var(--background-color-50);
}

.p-dialog .p-dialog-header {
    color: var(--complementary-color);
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
    color: var(--complementary-color);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: 0 0 0 0.2rem var(--complementary-color);
}

/* Accordion */
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link:hover {
    color: var(--complementary-color);
}
.p-accordion .p-accordion-header .p-accordion-header-link:hover {
    color: var(--complementary-color);
    background: var(--background-color);
}
.p-accordion .p-accordion-header .p-accordion-header-link {
    color: var(--complementary-color);
    background: var(--background-color-darker);
}
.p-accordion .p-accordion-content {
    background: var(--background-color-lighter);
}

/* Stepper */
.p-stepper .p-stepper-panels {
    background: var(--background-color);
}

/* Datatable */
.p-datatable .p-datatable-thead > tr > th {
    background: var(--background-color-darker);
    color: var(--complementary-color);
}
.p-datatable .p-datatable-tbody > tr > td {
    background: var(--background-color-darker);
    color: var(--complementary-color);
}
.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
    color: var(--complementary-color);
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: var(--complementary-color-50);
}
.p-checkbox {
    background: var(--background-color-darker);
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: var(--complementary-color);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: none;
    border-color: var(--complementary-color-50);
}
.p-paginator {
    background: var(--background-color-50);
    color: var(--complementary-color);
}
.p-dropdown {
    background: var(--background-color-darker);
    color: var(--complementary-color);
}