.custom-sidebar {
    height: 100vh;
    color: var(--complementary-color);
    position: absolute;
    top: 78px;
    width: 200px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    z-index: 998;
}

.pro-sidebar-inner {
    background: none !important;
}

.react-slidedown.pro-inner-list-item {
    background-color: transparent !important;
}

.pro-icon-wrapper {
    background-color: var(--icon-background-color) !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: var(--complementary-color-lighter);
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
    color: var(--complementary-color-lighter);
}