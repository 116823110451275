/* EditActions.css */
.p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon {
    color: var(--complementary-color);
}
.p-sidebar .p-sidebar-header .p-sidebar-close:focus, .p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
    box-shadow: 0 0 0 0.2rem var(--complementary-color);
}
.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover, .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    color: var(--complementary-color);
}
.p-button {
    color: var(--complementary-color);
}