.p-card-body {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 100%;
}

.p-card-content {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    height: 100%;
}

.image-responsive {
    max-height: 130px;
    width: auto;
    height: 100%; /* Ensure it respects the container's height */
    object-fit: cover; /* Maintain aspect ratio */
}

.image-container {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
}

.image-container img {
    max-height: 130px;
    width: auto;
    height: 100%;
    object-fit: cover;
}

.image-container .album-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 102px;
    margin-top: 12px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
}

.image-container:hover .album-overlay {
    opacity: 1;
}

/* Override button focus styles */
.p-button.p-button-rounded.p-button-text:focus {
    box-shadow: none; /* Removes the box shadow */
    outline: none;  /* Removes the outline */
}

/* Remove background color change on hover */
.p-button.p-button-rounded.p-button-text:hover {
    background-color: transparent; /* Keeps the background color unchanged */
    color: inherit; /* Optional: ensures the text color also remains unchanged */
}