.p-menubar {
    border: none !important;
    position: relative;
}

.p-menubar::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 200px; /* Default offset the border by 200px from the left */
    width: calc(100% - 200px); /* Adjust the width so it covers the rest of the top bar */
    border-bottom: 2px solid var(--background50-color); /* Apply the border with the specified color */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25); /* Add box shadow with black color and 25% transparency */
}

/* Media query for mobile devices or small screens */
@media screen and (max-width: 960px) {
    .p-menubar::after {
        left: 0; /* Remove the 200px offset */
        width: 100%; /* Make the width span the entire top bar */
    }
}