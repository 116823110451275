.card-container {
    position: relative;
    height: 250px;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    align-items: flex-end;
    transition: filter 0.05s;
    cursor: pointer;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: transform 0.05s;
}

.title, .overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    transition: transform 0.05s, opacity 0.05s, filter 0.05s;
    border-radius: 6px;
}

.title {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.overlay {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    top: 0; /* Ensure the overlay covers the entire card */
    height: 100%;
}

.description {
    max-height: 100%;
    overflow-y: auto;
}
