.player-input-text:hover {
    background-color: var(--input-text-hover-color) !important;
}

.player-input-text:focus {
    background-color: var(--input-text-hover-color) !important;
}

.p-divider.p-divider-horizontal:before {
    border-top: 1px solid rgba(117, 117, 117, 0.274);
}

.container {
    display: flex;
    flex-direction: row;
    max-height: 700px; /* Max height for the entire component */
}

.leftPanel,
.rightPanel {
    flex: 1; /* Each panel takes equal width */
    display: flex;
    flex-direction: column; /* Ensure child items stack vertically */
}

.conversationHistoryContainer,
.infoPanelContainer {
    flex: 1; /* Allows dynamic growth to fill available space */
    overflow-y: auto; /* Adds scroll if content overflows */
    padding: 16px;
    border-radius: 15px;
    box-shadow: inset 3px 3px 15px rgba(0, 0, 0, 0.5);
    margin-bottom: 16px;
}

.fixedHeightInput {
    flex: 0 0 50px; /* Fixed height for the InputText */
    margin-top: 16px;
}

.infoPanelItem {
    margin-bottom: 8px;
    overflow-y: auto;
    padding: 8px;
    border-radius: 10px;
    box-shadow: inset 3px 3px 15px rgba(255, 255, 255, 0.15);
}