.custom-input-number .p-inputtext {
    background-color: var(--background-color);
}

.p-inputtext:enabled:focus {
    box-shadow: none;
}

.p-togglebutton {
    box-shadow: none;
}