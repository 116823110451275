.p-tabview .p-tabview-panels {
    background: var(--panels-background);
    border-radius: 0px 15px 15px 15px;
    border-top: 1px solid var(--panel-highlighted);
}

.p-tabview .p-tabview-nav {
    border-bottom: none;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: var(--panel-highlighted);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: var(--panel-tab);
    border-bottom: 1px solid var(--panel-highlighted);
}

.p-tabview .p-tabview-nav-link:hover {
    background: var(--panel-tab-hover) !important;
}